///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  CustomInput,
  CustomNav,
  Modal,
  ModalBody,
  ModalHeader,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { logout } from "../../actions/user";
import { logout as logoutAdmin } from "../../actions/admin";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import scroll from "../../utils/scroll";
import { Language } from "../../utils/icons";
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ user, admin, logout, logoutAdmin }) => {
  // State
  const [modal, setModal] = useState(false);
  const [language, setLanguage] = useState("🇬🇧");

  // Language
  const [t, i18n] = useTranslation("global");

  // Toggle functions
  const toggle = (_) => setModal(!modal);

  // Navigate
  const navigate = useNavigate();

  // onChange functions
  const onLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
    localStorage.setItem("language", i18n.language);
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      const preselectedLang = localStorage.getItem("language");
      if (!!language) {
        setLanguage(preselectedLang);
        i18n.changeLanguage(preselectedLang);
      }
    } catch (e) {
      setLanguage("en");
      i18n.changeLanguage("en");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX
  return (
    <>
      <CustomNav
        dark={c.header.dark}
        blur={c.header.blur}
        logoSrc={c.mainPic.logo}
        logoStyle={c.header.styles}
        navbarBrand={c.cust}
        navbarBrandHref="#"
        navbarBrandOnClick={(_) => navigate("/")}
        fadeOnPaths={c.header.fadeOnPaths}
        navlinks={[
          {
            title: t("header.about"),
            href: "#",
            onClick: (_) => {
              navigate("/");
              setTimeout(() => {
                scroll(document.getElementById("about-section")?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: t("header.mechanics"),
            href: "#",
            onClick: (_) => {
              navigate("/mechanics");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: false,
          },
          {
            title: t("header.contact"),
            href: "#",
            onClick: (_) => {
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          {
            title: <Language size="s" />,
            href: "#",
            onClick: toggle,
            hide: false,
          },

          // auth links
          {
            title:
              (user && user?.user ? `${user?.user?.name}@${c.cust}` : "") ||
              (admin && admin?.admin ? `${admin?.admin?.name}@${c.cust}` : ""),
            href: "#",
            hide: !user?.isAuthenticated && !admin?.isAuthenticated,
            onClick: (_) => {
              if (user?.isAuthenticated) {
                navigate("/profile");
              }
              if (admin?.isAuthenticated) {
                navigate("/admin");
              }
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
          },

          {
            title: t("header.logout"),
            href: "#",
            hide: !user?.isAuthenticated && !admin?.isAuthenticated,
            onClick: (_) => {
              if (user?.isAuthenticated) {
                logout();
              }
              if (admin?.isAuthenticated) {
                logoutAdmin();
              }
            },
          },

          // guest links
          {
            title: t("header.login"),
            href: "#",
            onClick: (_) => {
              navigate("/auth");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: user?.isAuthenticated || admin?.isAuthenticated,
          },
        ]}
      />

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Set Language</ModalHeader>
        <ModalBody>
          <CustomInput
            type="select"
            value={language}
            onChange={onLanguageChange}
          >
            <option value="en">🇬🇧</option>
            <option value="nl">🇳🇱</option>
            <option value="fr">🇫🇷</option>
          </CustomInput>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user, admin: state.admin });

export default connect(mapStateToProps, { logout, logoutAdmin })(Header);
