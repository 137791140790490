///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Table,
  UncontrolledAccordion,
  CustomPagination,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
import {
  FcCellPhone,
  FcBusinesswoman,
  FcInvite,
  FcSettings,
  FcCalendar,
  FcPositiveDynamic,
  FcAlarmClock,
} from "react-icons/fc";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getLeads, putLead } from "../../actions/leads";
//////////////////////////////////////////////////////////////////////////////////////////

const Leads = ({ user, leads, getLeads, putLead }) => {
  // State
  const [page, setPage] = useState(1);

  // Translation
  const [t] = useTranslation("global");
  const skills = t("skills", { returnObjects: true });
  const jobStartTimesOptions = t("options.jobStartTimesOptions", {
    returnObjects: true,
  });

  // onClick functions
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  const onLeadClick = async (lead) => {
    try {
      if (!lead?.viewed) await putLead({ leadId: lead?._id, viewed: true });
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        await getLeads(
          `?mechanicId=${user?._id}&limit=10&skip=${
            10 * (page - 1)
          }&sortBy=createdAt:desc`
        );
      } catch (e) {
        console.error(e);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  // JSX
  return (
    <>
      <CustomPagination page={page} onPageClick={onPageClick} />
      <UncontrolledAccordion>
        {leads?.map((l, i) => (
          <AccordionItem key={i}>
            <AccordionHeader
              targetId={i}
              onClick={async (_) => onLeadClick(l)}
              size="sm"
            >
              <span>
                {!l.viewed && (
                  <>
                    <Badge color="success" pill>
                      <FcPositiveDynamic className="xs-icon" />
                      {t("leads.newLead")}
                    </Badge>{" "}
                    -
                  </>
                )}{" "}
                <span> {t(skills[l?.skill])}</span>
              </span>
            </AccordionHeader>

            <AccordionBody accordionId={i}>
              <Table borderless>
                <tbody>
                  <tr>
                    <th scope="row" className="t-gray">
                      <FcCalendar className="s-icon" /> {t("leads.leadDate")}
                    </th>
                    <td>{l?.createdAt}</td>
                  </tr>

                  <tr>
                    <th scope="row" className="t-gray">
                      <FcAlarmClock className="s-icon" /> {t("leads.urgency")}
                    </th>
                    <td>
                      {!!l?.startTime &&
                        jobStartTimesOptions.find(
                          (o) => o.value === l?.startTime
                        )?.label}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row" className="t-gray">
                      <FcBusinesswoman className="s-icon" />{" "}
                      {t("mechanic.name")}
                    </th>
                    <td>{l?.name}</td>
                  </tr>

                  <tr>
                    <th scope="row" className="t-gray">
                      <FcInvite className="s-icon" /> {t("inputs.email.label")}
                    </th>
                    <td>{l?.email}</td>
                  </tr>

                  <tr>
                    <th scope="row" className="t-gray">
                      <FcCellPhone className="s-icon" /> {t("mechanic.tel")}
                    </th>
                    <td>{l?.tel}</td>
                  </tr>

                  <tr>
                    <th scope="row" className="t-gray">
                      <FcSettings className="s-icon" />{" "}
                      {t("inputs.description.label")}
                    </th>
                    <td className="t-ws-p">{l?.body}</td>
                  </tr>
                </tbody>
              </Table>
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </>
  );
};

const mapStateToProps = (state) => ({ leads: state.leads });

export default connect(mapStateToProps, { getLeads, putLead })(Leads);
