///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { DropZone, ImageViewer } from "@ibiliaze/react-dropzone";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../../utils/httpRequest";
//////////////////////////////////////////////////////////////////////////////////////////

const SetImage = ({
  user,
  isAuthenticated,
  putUser,
  setAlert,
  id,
  imgClass,
  children,
  width,
  height,
}) => {
  // State
  const [imgSrc, setImgSrc] = useState(null);
  const [modal, setModal] = useState(false);

  // Helper functions
  const onUpload = async (img) => {
    const req = async (_) => {
      try {
        setAlert("Uploading image...", false, null, null, true);
        const formData = new FormData();
        formData.append("image", img);
        const res = await apiRequest({
          endpoint: `user/upload/${id}?width=${width}&height=${height}`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
        if (res.isError || !res?.secure_url)
          throw new Error(res?.message || "Failed to upload image");
        const imgPath = res?.secure_url;
        await putUser({ [id]: imgPath });
      } catch (e) {
        console.error(e);
        setAlert(e.message, true, null, null, false);
      }
    };

    req();
  };

  // Toggle functions
  const toggle = (_) => setModal(!modal);

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (id in user) {
        setImgSrc(user[id]);
      }
    } catch (e) {
      // console.error(e);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // JSX
  return isAuthenticated ? (
    <DropZone
      imgSrc={imgSrc}
      id={id}
      imgClass={imgClass}
      children={children}
      onUpload={onUpload}
    />
  ) : (
    <>
      {!!children ? (
        children
      ) : (
        <img
          onClick={toggle}
          className={imgClass}
          alt={id}
          src={imgSrc || "/img/albumplaceholder.jpg"}
        />
      )}
      <ImageViewer
        modal={modal}
        setModal={setModal}
        imgSrc={imgSrc || "/img/albumplaceholder.jpg"}
        id="album-img"
      />
    </>
  );
};

export default SetImage;
