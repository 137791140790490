///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  MainImage,
  Row,
  UncontrolledAccordion,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putUser } from "../../actions/user";
import { setAlert } from "../../actions/alert";
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from "../../hooks/useWindowSize";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import apiRequest from "../../utils/httpRequest";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Album from "../Profile/Album";
import Leads from "../Profile/Leads";
import Details from "../Profile/Details";
import CompanyInfo from "../Profile/CompanyInfo";
import Contact from "../Profile/Contact";
import Services from "../Profile/Services";
import Accreditations from "../Profile/Accreditations";
import Reviews from "../Profile/Reviews/Reviews";
import SetImage from "../Layout/SetImage";
//////////////////////////////////////////////////////////////////////////////////////////

const ProfilePage = ({ user, putUser, setAlert }) => {
  // State
  const [selectedUser, setSelectedUser] = useState(null);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  // Hooks
  const windowSize = useWindowSize();

  // Translation
  const [t] = useTranslation("global");

  // Navigate
  const navigate = useNavigate();

  // Location
  const { search } = useLocation();

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        const urlUserId = search.replace("?user=", "");

        if (!!urlUserId) {
          // Client clicks on a profile
          const mechanic = await apiRequest({
            endpoint: `user/${urlUserId}`,
            method: "GET",
          });

          // Verify we got the right object from the API
          if ("_id" in mechanic) {
          } else {
            navigate("/");
          }

          setSelectedUser(mechanic);
          setUserAuthenticated(
            user?.isAuthenticated && mechanic?._id === user?.user?._id
              ? true
              : false
          );
        } else if (user?.isAuthenticated) {
          setSelectedUser(user?.user);
          setUserAuthenticated(true);
        } else {
          navigate("/mechanics");
        }
      } catch (e) {
        console.error(e);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, user]);

  // JSX
  return (
    <FullPage>
      <SetImage
        user={selectedUser}
        isAuthenticated={userAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id="cover"
        imgClass=""
      >
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={false}
          imgPath={selectedUser?.cover || c.mainPic.path}
          title={selectedUser?.businessName}
          slogan={selectedUser?.slogan}
        />
      </SetImage>

      <SetImage
        user={selectedUser}
        isAuthenticated={userAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id="avatar"
        imgClass="avatar"
        width="150"
        height="150"
      />

      <Section className="custom-page below-header">
        <Row>
          <Col md={4}>
            <Details
              user={selectedUser}
              isAuthenticated={userAuthenticated}
              putUser={putUser}
            />
            <br />
          </Col>

          <Col md={8}>
            <div
              className={
                windowSize.width > c.mainPic.mobileWidth
                  ? "user-section"
                  : "user-section-mobile"
              }
            >
              <UncontrolledAccordion defaultOpen="3">
                {userAuthenticated && (
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <h4>{t("profileSections.leads")}</h4>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <Leads user={selectedUser} putUser={putUser} />
                    </AccordionBody>
                  </AccordionItem>
                )}

                <AccordionItem>
                  <AccordionHeader targetId="2">
                    <h4>{t("profileSections.servicesSkills")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <Services
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">
                    <h4>{t("profileSections.album")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <Album
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                      setAlert={setAlert}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="4">
                    <h4>{t("profileSections.reviews")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <Reviews
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">
                    <h4>{t("profileSections.compInfo")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="5">
                    <CompanyInfo
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    <h4>{t("profileSections.accreditations")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <Accreditations
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                    />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">
                    <h4>{t("profileSections.contactDetails")}</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId="7">
                    <Contact
                      user={selectedUser}
                      isAuthenticated={userAuthenticated}
                      putUser={putUser}
                    />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
            <br />
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { putUser, setAlert })(ProfilePage);
