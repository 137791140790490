///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import {
  ListGroupItemHeading,
  ListGroupItemText,
  Col,
  Row,
  Progress,
  Collapse,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
//////////////////////////////////////////////////////////////////////////////////////////

const Review = ({ review }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(0);

  // Translation
  const [t] = useTranslation("global");

  // Toggle functions
  const toggleCollapse = () => setIsOpen(!isOpen);

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        setRating(
          (
            (review.qualityOfWork +
              review.reliability +
              review.communication +
              review.tidiness) /
            4
          ).toFixed(2) || 0
        );
      } catch (e) {
        setRating(0);
        console.error(e);
      }
    };
    req();
  }, [review]);

  // JSX
  return (
    <div className="c-p" onClick={toggleCollapse}>
      <Row>
        <ListGroupItemHeading>
          <span className="text-gradient t-f-s-ll">{rating}</span> -{" "}
          {review.job}
        </ListGroupItemHeading>

        <ListGroupItemText className="t-gray t-f-s-s">
          {review.body}
        </ListGroupItemText>
        <ListGroupItemText className="t-gray t-f-s-s">
          {review.createdAt}
        </ListGroupItemText>

        <Collapse isOpen={isOpen}>
          <br />
          <Row>
            <Col md={6} className="t-f-s-s">
              {t("ratings.qualityOfWork")}
              <Progress
                className="my-3"
                style={{
                  height: "3px",
                }}
                value={review.qualityOfWork * 10}
              />
              <br />
              {t("ratings.reliability")}
              <Progress
                className="my-3"
                style={{
                  height: "3px",
                }}
                value={review.reliability * 10}
              />
              <br />
            </Col>
            <Col md={6} className="t-f-s-s">
              {t("ratings.tidiness")}
              <Progress
                className="my-3"
                style={{
                  height: "3px",
                }}
                value={review.tidiness * 10}
              />
              <br />
              {t("ratings.communication")}
              <Progress
                className="my-3"
                style={{
                  height: "3px",
                }}
                value={review.communication * 10}
              />
            </Col>
          </Row>
        </Collapse>
      </Row>
    </div>
  );
};

export default Review;
