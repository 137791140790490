///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Form,
  Spinner,
  CustomPagination,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUsers, putUser } from "../../../actions/user";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import MechanicInputsTable from "./MechanicInputsTable";
import MechanicsHeader from "./MechanicsHeader";
import MechanicsList from "./MechanicsList";
//////////////////////////////////////////////////////////////////////////////////////////

const Mechanics = ({ users, getUsers, putUser }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    businessName: "",
    status: "",
    sortBy: "",
    mechanicId: "",
    searchBy: "Name",
  });
  const [mechanic, setMechanic] = useState(null);

  // Toggle functions
  const toggle = (_) => setCanvas(!canvas);

  // Translation
  const [t] = useTranslation("global");
  const accStatusOptions = t("options.accStatusOptions", {
    returnObjects: true,
  });

  // onChange functions
  const onInputsChange = (e) =>
    setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onMechanicInputsChange = (e) =>
    setMechanic((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onMechanicClick = (t) => {
    toggle();
    setMechanic(t);
  };
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        setLoading(true);
        const businessNameEndpoint = `&businessName=${inputs.businessName}`;
        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const statusEndpoint =
          inputs.status === "All" || !inputs.status
            ? ""
            : `&status=${inputs.status}`;
        const limitEndpoint = `&limit=20&skip=${20 * (page - 1)}`;
        if (inputs.searchBy === "Name") {
          await getUsers(
            "?" +
              businessNameEndpoint +
              limitEndpoint +
              sortEndpoint +
              statusEndpoint
          );
        } else {
          await getUsers(
            "?_id=" +
              inputs.mechanicId +
              limitEndpoint +
              sortEndpoint +
              statusEndpoint
          );
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, inputs]);

  // onSubmit functions
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await putUser({
        endpoint: "/verify",
        status: mechanic.status,
        _id: mechanic._id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <MechanicsHeader inputs={inputs} onInputsChange={onInputsChange} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? (
        <Spinner color="primary" />
      ) : (
        <MechanicsList users={users} onMechanicClick={onMechanicClick} />
      )}

      {!!mechanic && typeof mechanic === "object" && (
        <Offcanvas
          direction="start"
          fade
          scrollable
          isOpen={canvas}
          toggle={toggle}
        >
          <OffcanvasHeader toggle={toggle}>
            Mechanic Account Manager
          </OffcanvasHeader>
          <OffcanvasBody>
            <CustomButton
              onClick={(_) => window.open(`/profile?user=${mechanic._id}`)}
              color="primary"
              size="sm"
            >
              {t("buttons.viewAccount")}
            </CustomButton>
            <br />
            <MechanicInputsTable mechanic={mechanic} />

            <hr />

            <Form onSubmit={onSubmit}>
              <FormGroup floating>
                <CustomInput
                  type="select"
                  placeholder=""
                  name="status"
                  value={mechanic.status}
                  onChange={onMechanicInputsChange}
                >
                  <option value={t("options.all.value")}>
                    {t("options.all.label")}
                  </option>
                  {accStatusOptions.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </CustomInput>
                <Label>Account Status</Label>
              </FormGroup>

              <CustomButton type="submit" color="primary">
                {t("buttons.save")}
              </CustomButton>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ users: state.user.users });

export default connect(mapStateToProps, { getUsers, putUser })(Mechanics);
