///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  CustomListGroup,
  CustomListGroupItem,
  CustomPagination,
} from "@ibiliaze/reactstrap";
import { useTranslation } from "react-i18next";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getReviews } from "../../../actions/reviews";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ReviewModal from "./ReviewModal";
import Review from "./Review";
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = ({ user, reviews, getReviews }) => {
  // State
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);

  // Translation
  const [t] = useTranslation("global");

  // Toggle functions
  const toggle = (_) => setModal(!modal);

  // onClick functions
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        await getReviews(
          `?mechanicId=${user?._id}&limit=3&skip=${
            3 * (page - 1)
          }&verified=true`
        );
      } catch (e) {
        console.error(e);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  // JSX
  return (
    <>
      <h5>
        {t("review.rating")}:{" "}
        <span className="text-gradient t-f-s-ll">
          {Math.floor(user?.rating)}
        </span>
        <span className="t-f-s-s">/100</span>
      </h5>
      <h6>
        <span className="text-gradient">
          {user?.reviewCount} {t("review.verifiedReviews")}
        </span>
      </h6>

      <div className="t-align-c">
        <Button color="primary" onClick={toggle}>
          {t("buttons.leaveReview")}
        </Button>
      </div>

      <br />

      <ReviewModal mechanicId={user?._id} modal={modal} toggle={toggle} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      <CustomListGroup>
        {reviews.map((r, i) => (
          <CustomListGroupItem key={i}>
            <Review review={r} />
          </CustomListGroupItem>
        ))}
      </CustomListGroup>
    </>
  );
};

const mapStateToProps = (state) => ({ reviews: state.reviews });

export default connect(mapStateToProps, { getReviews })(Reviews);
