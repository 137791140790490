///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner, CustomPagination } from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getLogs } from "../../../actions/logs";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import LogsHeader from "./LogsHeader";
import LogsList from "./LogsList";
//////////////////////////////////////////////////////////////////////////////////////////

const Logs = ({ logs, getLogs }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({ sortBy: "" });

  // onChange functions
  const onInputsChange = (e) =>
    setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      try {
        setLoading(true);
        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const limitEndpoint = `&limit=20&skip=${20 * (page - 1)}`;

        await getLogs("?" + sortEndpoint + limitEndpoint);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, inputs]);

  // JSX
  return (
    <>
      <LogsHeader inputs={inputs} onInputsChange={onInputsChange} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? <Spinner color="primary" /> : <LogsList logs={logs} />}
    </>
  );
};

const mapStateToProps = (state) => ({ logs: state.logs });

export default connect(mapStateToProps, { getLogs })(Logs);
